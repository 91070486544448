import { LatLngExpression } from "leaflet";
import React, { ReactElement } from "react";
import { GeneralPolygon } from "./shapes/GeneralPolygon";
import { GLXStations } from "./shapes/GLXStations";

export const communityPath: LatLngExpression[] = [
  [42.3929031, -71.1062442],
  [42.3926654, -71.1063515],
  [42.3923563, -71.1055307],
  [42.3918849, -71.1049084],
  [42.3912549, -71.1042164],
  [42.3907121, -71.1035459],
  [42.3898246, -71.101325],
  [42.3882755, -71.0978971],
  [42.3877406, -71.0967867],
  [42.3873761, -71.0959981],
  [42.3868966, -71.0948877],
  [42.3862072, -71.0937612],
  [42.3853514, -71.0926561],
  [42.3847174, -71.0920124],
  [42.3839804, -71.0913364],
  [42.3826371, -71.0901294],
  [42.3814563, -71.0889761],
  [42.380113, -71.0876725],
  [42.3785596, -71.0861115],
  [42.3763365, -71.0840194],
  [42.3748266, -71.0826139],
  [42.3744461, -71.0820023],
  [42.3738992, -71.0811118],
  [42.3735743, -71.0802106],
  [42.3729392, -71.0774565],
  [42.3720079, -71.0758794],
  [42.3721704, -71.0757185],
  [42.3731414, -71.077419],
  [42.3737794, -71.0801334],
  [42.3740687, -71.0809702],
  [42.3745443, -71.0815657],
  [42.3749604, -71.0823382],
  [42.3770964, -71.0843445],
  [42.3794701, -71.0866726],
  [42.3812216, -71.0884322],
  [42.3830522, -71.0901649],
  [42.3850453, -71.0920532],
  [42.3854733, -71.092493],
  [42.3862459, -71.0934425],
  [42.3866817, -71.0941238],
  [42.3869868, -71.0947354],
  [42.3879496, -71.096908],
  [42.3890709, -71.0992898],
  [42.3904339, -71.1023207],
  [42.3908221, -71.1033614],
  [42.391353, -71.1040265],
  [42.3919988, -71.1047722],
  [42.392395, -71.1052657],
  [42.3927199, -71.1058773],
];
export const GLX_STATIONS: LatLngExpression[] = [
  [42.4079561513032, -71.1170347551837],
  [42.39989309559492, -71.11099159126597],
  [42.39369289187466, -71.10637922458356],
  [42.38793113145654, -71.09674141303367],
  [42.379470147186424, -71.08662715569756],
  [42.37735987718784, -71.09475896465707],
  [42.37153740162477, -71.07654084480168],
];

export const PROJECT_OUTLINES: {
  [key: string]: { key: string; shape: ReactElement; name: string };
} = {
  "1": {
    key: "1",
    name: "Community Path Extension",
    shape: <GeneralPolygon shapes={communityPath} />,
  },
  "2": { key: "2", name: "GLX Stations", shape: <GLXStations /> },
};
